import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import Select from 'react-select';
import ManageProduct from '../../../products/Manage';
import ManageProductBatches from '../../../products-lots/Manage';
import { IndexProductAndBatch } from '../../../../../services/ProductService';
import PropTypes from 'prop-types';
import { PutPrestation } from '../../../../../services/PrestationService';
import { toast } from 'react-toastify';
import FormAlert from '../../../commons/FormAlert';
import CanvaNotes from '../../../canvas-components/canvaNotes';
import { IndexProductionZone } from '../../../../../services/ProductionZoneService';
import { v4 as uuidv4 } from 'uuid';
import EditProductOrder from './editProductOrder';
import ManageSection from './manageSection';
import ManageProductBatchesPrestation from '../ManageProductBatchesPrestation';
import Tippy from '@tippyjs/react';
import * as Moment from 'moment/moment';

const ManageProductsService = ({ prestation, setParentTrigger }) => {
  const [showCanvaAddProduct, setShowCanvaAddProduct] = useState(false);
  const [showCanvaAddBatch, setShowCanvaAddBatch] = useState(false);
  const [showCanvaAddBatchFromPrestation, setShowCanvaAddBatchFromPrestation] =
    useState(false);
  const [initialApiProducts, setInitialApiProducts] = useState([]);
  const [labelProducts, setLabelProducts] = useState([]);
  const [labelZones, setLabelZones] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [selectedProductInput, setSelectedProductInput] = useState(null);
  const [isProductsModified, setIsProductsModified] = useState(false);

  const [formData, setFormData] = useState({
    sections: [],
    products: []
  });
  const [initialFormDataString, setInitialFormDataString] = useState('');

  if (selectedProductInput === null) {
    let tmpProducts = [];
    tmpProducts = prestation.products.filter(p => p.is_batch === 1);
    setSelectedProductInput(tmpProducts);
  }
  const myRef = useRef(null);
  const useMountEffect = fun => useEffect(fun, []);

  const executeScroll = () => myRef.current.scrollIntoView();
  useMountEffect(executeScroll); // Scroll on mount

  const customFilterForProductsFilter = useCallback((product, input) => {
    if (input) {
      if (
        product.data &&
        product.data.originalLabel &&
        product.data.originalLabel
          .toLowerCase()
          .includes(input.toLowerCase().replace('\t', ' '))
      ) {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  useEffect(() => {
    const formDataString = JSON.stringify(formData);
    if (formDataString !== initialFormDataString) {
      setIsProductsModified(true);
    } else {
      setIsProductsModified(false);
    }
  }, [formData, initialFormDataString]);

  useEffect(() => {
    setInitialFormDataString(JSON.stringify(formData));
  }, [initialApiProducts]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    let tmpFormData = { ...formData };
    tmpFormData = {
      ...tmpFormData,
      products: tmpFormData.products?.map(p => {
        return {
          product_id: p.product_id,
          section_id: p.section_id,
          quantity: p.quantity,
          price: p.price,
          discount: p.discount || 0,
          vat: p.vat,
          unit: p.unit,
          zone_id: p.zone_id,
          position: p.position
        };
      }),
      context: 'products'
    };
    let response = false;

    response = await PutPrestation(prestation.id, tmpFormData);
    if (response.success === true) {
      toast('Produits enregistrés');
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
      executeScroll();
    }
    setIsLoadingSubmit(false);
    setErrorForm(response.validator);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const responseProducts = await IndexProductAndBatch(prestation.id);
      if (responseProducts.success === true) {
        setInitialApiProducts(responseProducts.data);
        const dataProducts = responseProducts.data;
        if (dataProducts && dataProducts.length > 0) {
          applyFilterSeasonsProducts(dataProducts, 1);
        }
      }
    };
    let tmpFormData = { ...formData };
    tmpFormData.sections = prestation.sections;
    tmpFormData.products = [];
    prestation.products.map(product => {
      tmpFormData.products.push({
        uuid: uuidv4(),
        select: {
          value: product.id,
          label: (
            <>
              {product.booklet_name}
              {product.is_batch &&
              product.prestation &&
              product.prestation.date ? (
                <small>
                  <small>
                    {product.prestation.name}{' '}
                    {Moment(product.prestation.date)
                      .local()
                      .format('DD/MM/YYYY')}
                  </small>
                </small>
              ) : (
                ''
              )}
            </>
          )
        },
        id: product.id,
        product_id: product.id,
        quantity: product.pivot.quantity,
        unit: product.pivot.unit,
        price: product.pivot.price,
        discount: product.pivot.discount || 0,
        vat: product.pivot.vat,
        zone_id: product.pivot.zone_id,
        section_id: product.pivot.section_id,
        position: product.pivot.position,
        is_batch: product.is_batch,
        reference: product.reference
      });
    });
    const fetchAllProductionZones = async () => {
      const responseProductionZones = await IndexProductionZone();
      if (responseProductionZones.success === true) {
        let tmpZonesArray = [];
        responseProductionZones.data.map(zone => {
          tmpZonesArray.push({
            value: zone.id,
            label: zone.name
          });
        });
        setLabelZones(tmpZonesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    setFormData(tmpFormData);
    fetchProducts();
    fetchAllProductionZones();
  }, [trigger, prestation]);

  const applyFilterSeasonsProducts = (data, active) => {
    let tmpLabelProducts = [];
    let filterSeasonsProducts = [];
    if (active === 1) {
      filterSeasonsProducts = data.filter(product => {
        let showProduct = false;
        if (!product.seasons || product.seasons.length === 0) {
          showProduct = true;
        }
        product.seasons.map(season => {
          const startDateSeason = new Date(season.start);
          const endDateSeason = new Date(season.end);
          const datePresta = new Date(prestation.date);
          if (
            datePresta.getTime() >= startDateSeason.getTime() &&
            datePresta.getTime() <= endDateSeason.getTime()
          ) {
            showProduct = true;
          }
        });
        return showProduct;
      });
    } else {
      filterSeasonsProducts = data;
    }
    if (filterSeasonsProducts && filterSeasonsProducts.length > 0) {
      filterSeasonsProducts.map(product => {
        tmpLabelProducts.push({
          value: product.id,
          originalLabel: product.booklet_name,
          price: product.default_price,
          vat: product.default_vat,
          zone_id: product.default_zone_id,
          is_batch: product.is_batch,
          unit: product.default_unit,
          label: (
            <>
              <div className="flex-center">
                {product.is_batch === 1 && (
                  <Tippy
                    placement={'bottom'}
                    content={
                      product.products && product.products.length > 0
                        ? product.products.map((obj, index) => (
                            <span key={index}>
                              {' '}
                              - x {obj.pivot.quantity}
                              {obj.pivot.default_unit}{' '}
                              {obj.production_sheet_name}{' '}
                            </span>
                          ))
                        : 'Aucun produit enregistré sur ce lot'
                    }
                  >
                    <span className="me-1">
                      <svg
                        style={{ marginBottom: '2px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="15"
                        height="15"
                      >
                        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path>
                      </svg>
                    </span>
                  </Tippy>
                )}
                {product.booklet_name}
              </div>
              {product.is_batch &&
              product.prestation &&
              product.prestation.date ? (
                <p
                  style={{ fontSize: '11px', fontWeight: 600 }}
                  className="text-muted mt-1 mb-0 pb-0"
                >
                  {' '}
                  {product.prestation.name}
                  <span className="mx-1">•</span>
                  {Moment(product.prestation.date).local().format('DD/MM/YYYY')}
                </p>
              ) : (
                ''
              )}
            </>
          )
        });
      });
    }
    setLabelProducts(tmpLabelProducts);
  };

  useEffect(() => {}, [labelProducts]);

  return (
    <>
      {showCanvaAddBatch && (
        <ManageProductBatches
          showCanvaProduct={showCanvaAddBatch}
          setShowCanvaProduct={setShowCanvaAddBatch}
          selectedObject={{}}
          setParentTrigger={setTrigger}
        />
      )}
      {showCanvaAddBatchFromPrestation && selectedProductInput && (
        <ManageProductBatchesPrestation
          showCanvaAddBatchFromPrestation={showCanvaAddBatchFromPrestation}
          setShowCanvaAddBatchFromPrestation={
            setShowCanvaAddBatchFromPrestation
          }
          selectedProductInput={selectedProductInput}
          setSelectedProductInput={setSelectedProductInput}
          prestation={prestation}
          setParentTrigger={setTrigger}
        />
      )}
      <Col>
        <Card className="mt-3">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Col className="d-flex" md="6">
                  <h5 className="pt-1">Produits</h5>
                </Col>
              </Accordion.Header>
              <Accordion.Body ref={myRef}>
                {errorForm && (
                  <div className="mt-3" id="errorForm">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                <Row lg={12}>
                  <Row>
                    <Col xs={6}>
                      <Form.Check
                        className="ms-0"
                        type="checkbox"
                        id="defaultCheckbox"
                        onChange={e => {
                          if (e.target.checked) {
                            applyFilterSeasonsProducts(initialApiProducts, 0);
                          } else {
                            applyFilterSeasonsProducts(initialApiProducts, 1);
                          }
                        }}
                        label={
                          <small className="mb-1">
                            Voir tous les produits (hors saisons)
                          </small>
                        }
                      />
                      <a
                        onClick={() => {
                          if (isProductsModified) return;
                          setShowCanvaAddProduct(true);
                        }}
                        className="cursor-pointer d-inline mb-5"
                        style={
                          isProductsModified
                            ? {
                                color: 'gray',
                                textDecoration: 'none',
                                cursor: 'default'
                              }
                            : {}
                        }
                      >
                        {isProductsModified ? (
                          <Tippy content={'Veuillez enregistrer les produits'}>
                            <small>+ Créer un nouveau produit</small>
                          </Tippy>
                        ) : (
                          <small>+ Créer un nouveau produit</small>
                        )}
                      </a>
                      <a
                        onClick={() => {
                          if (isProductsModified) return;
                          setShowCanvaAddBatch(true);
                        }}
                        className="cursor-pointer d-inline mb-5 ms-3"
                        style={
                          isProductsModified
                            ? {
                                color: 'gray',
                                textDecoration: 'none',
                                cursor: 'default'
                              }
                            : {}
                        }
                      >
                        {isProductsModified ? (
                          <Tippy content={'Veuillez enregistrer les produits'}>
                            <small>+ Créer un nouveau lot</small>
                          </Tippy>
                        ) : (
                          <small>+ Créer un nouveau lot</small>
                        )}
                      </a>
                    </Col>
                    <Col xs={6} className="text-end pe-0">
                      {/*  bouton des notes*/}
                      <CanvaNotes
                        model={prestation}
                        noteableType={'prestation'}
                        setTrigger={setParentTrigger}
                        context={'products'}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-0 mt-3">
                    <Col xs={12}>
                      {formData.sections?.length > 0 && (
                        <>
                          {formData.sections.map((section, index) => (
                            <div key={index}>
                              <table className="table table-striped mb-5">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th style={{ width: 300 }}>
                                      <b>
                                        {section.name}{' '}
                                        <ManageSection
                                          formData={formData}
                                          setFormData={setFormData}
                                          prestation={prestation}
                                          section={section}
                                        />
                                      </b>
                                      <span className="float-end mt-1">
                                        {index > 0 && (
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const tmpFormData = {
                                                  ...formData
                                                },
                                                temp =
                                                  tmpFormData.sections[index];
                                              tmpFormData.sections[index] =
                                                tmpFormData.sections[index - 1];
                                              tmpFormData.sections[index - 1] =
                                                temp;
                                              setFormData(tmpFormData);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              width="24"
                                              height="24"
                                            >
                                              <path
                                                fill="none"
                                                d="M0 0h24v24H0z"
                                              />
                                              <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                                            </svg>
                                          </span>
                                        )}
                                        {index <
                                          formData.sections.length - 1 && (
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const tmpFormData = {
                                                  ...formData
                                                },
                                                temp =
                                                  tmpFormData.sections[index];
                                              tmpFormData.sections[index] =
                                                tmpFormData.sections[index + 1];
                                              tmpFormData.sections[index + 1] =
                                                temp;
                                              setFormData(tmpFormData);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              width="24"
                                              height="24"
                                            >
                                              <path
                                                fill="none"
                                                d="M0 0h24v24H0z"
                                              />
                                              <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                                            </svg>
                                          </span>
                                        )}
                                      </span>
                                    </th>
                                    <th>Quantité</th>
                                    <th>Unité</th>
                                    <th>Prix €</th>
                                    <th>Remise %</th>
                                    <th>TVA %</th>
                                    <th>Zone</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formData.products
                                    .filter(p => p.section_id === section.id)
                                    .sort((a, b) => {
                                      return a.position - b.position;
                                    })
                                    .map((product, index) => (
                                      <Fragment key={index}>
                                        <tr key={index}>
                                          <td>
                                            <EditProductOrder
                                              index={index}
                                              formData={formData}
                                              setFormData={setFormData}
                                              section={section}
                                              product={product}
                                            />
                                          </td>
                                          <td>
                                            <Select
                                              filterOption={
                                                customFilterForProductsFilter
                                              }
                                              closeMenuOnSelect={true}
                                              options={labelProducts}
                                              value={product.select}
                                              placeholder="Sélectionner un produit ..."
                                              classNamePrefix="react-select"
                                              onChange={object => {
                                                let selectedProduct =
                                                  labelProducts.find(
                                                    product =>
                                                      product.value ===
                                                      object.value
                                                  );
                                                if (selectedProduct) {
                                                  let tmpFormData = {
                                                    ...formData
                                                  };
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].select = object;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].id =
                                                    selectedProduct.value;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].product_id =
                                                    selectedProduct.value;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].quantity = 0;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].unit =
                                                    selectedProduct.unit;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].is_batch =
                                                    selectedProduct.is_batch;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].price =
                                                    selectedProduct.price;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].discount = 0;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].zone_id =
                                                    selectedProduct.zone_id;
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].vat =
                                                    selectedProduct.vat;
                                                  setFormData(tmpFormData);
                                                  setSelectedProductInput(
                                                    selectedProduct
                                                  );
                                                }
                                              }}
                                            />
                                            {product.is_batch === 1 && (
                                              <a
                                                id="saveBefore"
                                                style={
                                                  isProductsModified
                                                    ? {
                                                        color: 'gray',
                                                        textDecoration: 'none',
                                                        cursor: 'default'
                                                      }
                                                    : {}
                                                }
                                                onClick={() => {
                                                  if (isProductsModified)
                                                    return;
                                                  setShowCanvaAddBatchFromPrestation(
                                                    true
                                                  );
                                                  setSelectedProductInput(
                                                    product
                                                  );
                                                }}
                                                className={`${
                                                  isProductsModified
                                                    ? ''
                                                    : 'cursor-pointer'
                                                } ms-2`}
                                              >
                                                {isProductsModified ? (
                                                  <Tippy
                                                    content={
                                                      'Veuillez enregistrer les produits'
                                                    }
                                                  >
                                                    <small>
                                                      Dupliquer le lot pour
                                                      cette prestation
                                                    </small>
                                                  </Tippy>
                                                ) : (
                                                  <small>
                                                    Dupliquer le lot pour cette
                                                    prestation
                                                  </small>
                                                )}
                                              </a>
                                            )}
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={1}
                                              name="quantity"
                                              value={product.quantity}
                                              placeholder="Quantité du produit"
                                              onChange={e => {
                                                let tmpFormData = {
                                                  ...formData
                                                };
                                                tmpFormData.products
                                                  .filter(
                                                    p =>
                                                      p.section_id ===
                                                      section.id
                                                  )
                                                  .sort((a, b) => {
                                                    return (
                                                      a.position - b.position
                                                    );
                                                  })[index].quantity =
                                                  e.target.value;
                                                setFormData(tmpFormData);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            {product.is_batch !== 1 && (
                                              <Form.Select
                                                name="unit"
                                                placeholder="Choisir"
                                                onChange={e => {
                                                  let tmpFormData = {
                                                    ...formData
                                                  };
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].unit =
                                                    e.target.value;
                                                  setFormData(tmpFormData);
                                                }}
                                                value={product.unit}
                                              >
                                                <option value="kilo">
                                                  Kilo
                                                </option>
                                                <option value="part">
                                                  Part
                                                </option>
                                                <option value="piece">
                                                  Pièce
                                                </option>
                                                <option value="liter">
                                                  Litre
                                                </option>
                                              </Form.Select>
                                            )}
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              name="price"
                                              placeholder="Prix"
                                              onChange={e => {
                                                let tmpFormData = {
                                                  ...formData
                                                };
                                                tmpFormData.products
                                                  .filter(
                                                    p =>
                                                      p.section_id ===
                                                      section.id
                                                  )
                                                  .sort((a, b) => {
                                                    return (
                                                      a.position - b.position
                                                    );
                                                  })[index].price =
                                                  e.target.value;
                                                setFormData(tmpFormData);
                                              }}
                                              value={product.price}
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              name="discount"
                                              placeholder="Remise"
                                              onChange={e => {
                                                let tmpFormData = {
                                                  ...formData
                                                };
                                                tmpFormData.products
                                                  .filter(
                                                    p =>
                                                      p.section_id ===
                                                      section.id
                                                  )
                                                  .sort((a, b) => {
                                                    return (
                                                      a.position - b.position
                                                    );
                                                  })[index].discount =
                                                  e.target.value;
                                                setFormData(tmpFormData);
                                              }}
                                              value={product.discount}
                                            />
                                          </td>
                                          <td>
                                            <Form.Select
                                              value={product.vat}
                                              type="number"
                                              name="vat"
                                              placeholder="Taux TVA"
                                              onChange={e => {
                                                let tmpFormData = {
                                                  ...formData
                                                };
                                                tmpFormData.products
                                                  .filter(
                                                    p =>
                                                      p.section_id ===
                                                      section.id
                                                  )
                                                  .sort((a, b) => {
                                                    return (
                                                      a.position - b.position
                                                    );
                                                  })[index].vat =
                                                  e.target.value;
                                                setFormData(tmpFormData);
                                              }}
                                            >
                                              {product.vat === '10.00' ? (
                                                <>
                                                  <option value={'10.00'}>
                                                    10
                                                  </option>
                                                  <option value={'5.50'}>
                                                    5,5
                                                  </option>
                                                  <option value={'20.00'}>
                                                    20
                                                  </option>
                                                </>
                                              ) : product.vat === '20.00' ? (
                                                <>
                                                  <option value={'20.00'}>
                                                    20
                                                  </option>
                                                  <option value={'5.50'}>
                                                    5,50
                                                  </option>
                                                  <option value={'10.00'}>
                                                    10
                                                  </option>
                                                </>
                                              ) : (
                                                <>
                                                  <option value={'5.50'}>
                                                    5,50
                                                  </option>
                                                  <option value={'20.00'}>
                                                    20
                                                  </option>
                                                  <option value={'10.00'}>
                                                    10
                                                  </option>
                                                </>
                                              )}
                                            </Form.Select>
                                          </td>
                                          <td>
                                            {product.is_batch !== 1 && (
                                              <Form.Select
                                                name="zone"
                                                value={product.zone_id}
                                                placeholder="Choisir ?"
                                                onChange={e => {
                                                  let tmpFormData = {
                                                    ...formData
                                                  };
                                                  tmpFormData.products
                                                    .filter(
                                                      p =>
                                                        p.section_id ===
                                                        section.id
                                                    )
                                                    .sort((a, b) => {
                                                      return (
                                                        a.position - b.position
                                                      );
                                                    })[index].zone_id =
                                                    e.target.value;
                                                  setFormData(tmpFormData);
                                                }}
                                              >
                                                {labelZones.length > 0 && (
                                                  <Fragment>
                                                    {labelZones.map(zone => (
                                                      <option
                                                        key={zone.value}
                                                        value={zone.value}
                                                      >
                                                        {zone.label}
                                                      </option>
                                                    ))}
                                                  </Fragment>
                                                )}
                                              </Form.Select>
                                            )}
                                          </td>
                                        </tr>
                                      </Fragment>
                                    ))}
                                  <tr>
                                    <td colSpan={8}>
                                      <Button
                                        size="sm"
                                        variant="falcon-default"
                                        onClick={() => {
                                          const newArray = { ...formData };
                                          if (!newArray.products) {
                                            newArray.products = [];
                                          }
                                          newArray.products.push({
                                            select: {},
                                            uuid: uuidv4(),
                                            id: '',
                                            quantity: 10,
                                            unit: '',
                                            price: '',
                                            zone: '',
                                            vat: '',
                                            section_id: section.id,
                                            is_batch: 0,
                                            position:
                                              formData.products.filter(
                                                p => p.section_id === section.id
                                              ).length + 1
                                          });
                                          setFormData(newArray);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path
                                            d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                                            fill="rgba(94,110,130,1)"
                                          />
                                        </svg>{' '}
                                        Ajouter un produit / lot
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ))}
                        </>
                      )}
                      <ManageSection
                        formData={formData}
                        setFormData={setFormData}
                        prestation={prestation}
                        setParentTrigger={setParentTrigger}
                      />
                    </Col>
                  </Row>
                </Row>

                {formData.products.length > 0 && (
                  <div
                    style={{
                      position: 'sticky',
                      bottom: 0,
                      backgroundColor: 'white',
                      padding: '10px',
                      borderTop: '1px solid #dee2e6',
                      textAlign: 'left',
                      marginTop: '20px',
                      marginLeft: '-20px',
                      marginRight: '-20px',
                      zIndex: 1
                    }}
                  >
                    <Button
                      disabled={isLoadingSubmit}
                      size="sm"
                      variant="primary"
                      onClick={handleSubmit}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>{' '}
                      Enregistrer les produits{' '}
                    </Button>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Col>
      {/*------------------          Offcanva création d'une ressource        ---------------------*/}
      {showCanvaAddProduct && (
        <ManageProduct
          showCanvaProduct={showCanvaAddProduct}
          setShowCanvaProduct={setShowCanvaAddProduct}
          selectedObject={{}}
          setParentTrigger={setTrigger}
        />
      )}{' '}
    </>
  );
};

ManageProductsService.propTypes = {
  prestation: PropTypes.object,
  setParentTrigger: PropTypes.func
};

export default ManageProductsService;
