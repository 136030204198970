import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { PutPrestation } from '../../../../services/PrestationService';
import { IndexResources } from '../../../../services/RessourceService';
import CanvaNotes from '../../canvas-components/canvaNotes';
import FormAlert from '../../commons/FormAlert';
import ManageResource from '../../resources/ManageResource';

const ManageLogisticService = ({
  prestation,
  labelResources,
  setLabelResources,
  setParentTrigger
}) => {
  const [showCanvaAddResource, setShowCanvaAddResource] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [formData, setFormData] = useState({ resources: [] });
  const myRef = useRef(null);
  const [childrenOptions, setChildrenOptions] = useState([]);

  const useMountEffect = fun => useEffect(fun, []);
  const executeScroll = () => myRef.current.scrollIntoView();
  useMountEffect(executeScroll);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    let tmpFormData = { ...formData };
    tmpFormData = {
      ...tmpFormData,
      resources: tmpFormData.resources?.map(r => {
        let resourceData = {
          resource_id: r.resource_id,
          quantity: r.quantity,
          quantity_production_sheet: r.quantity_production_sheet,
          price: r.price,
          discount: r.discount,
          vat: r.vat,
          consumed: r.consumed
        };
        if (r.isVaisselle && r.children) {
          resourceData.children = r.children
            .filter(c => c.name && c.name.trim() !== '')
            .map(c => ({ id: c.id, quantity: c.quantity }));
        }
        return resourceData;
      }),
      context: 'resources'
    };

    let response = false;
    response = await PutPrestation(prestation.id, tmpFormData);
    if (response.success === true) {
      toast('Ressources enregistrées');
      setParentTrigger(Date.now());
      setTimeout(() => {
        setTrigger(Date.now());
      }, 1000);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
      executeScroll();
    }
    setErrorForm(response.validator);
    setIsLoadingSubmit(false);
  };

  useEffect(() => {
    const fetchResources = async () => {
      const responseResources = await IndexResources();
      if (responseResources.success === true) {
        const allowedCategoryIds = [2, 8, 15, 3];
        let tmpLabelResources = [];
        let tmpChildrenOptions = [];

        responseResources.data.forEach(resource => {
          let label =
            resource.category?.name === 'Logistique'
              ? `${resource.invoice_name} (LOGISTIQUE)`
              : resource.invoice_name;

          tmpLabelResources.push({
            value: resource.id,
            label: label,
            name: resource.invoice_name,
            price: resource.default_price,
            vat: resource.default_vat,
            isVaisselle: resource.isVaisselle,
            category_id: resource.category_id
          });

          // Ajoutez les ressources avec les IDs autorisés aux options d'enfants
          if (allowedCategoryIds.includes(resource.category_id)) {
            tmpChildrenOptions.push({
              value: resource.id,
              label: resource.service_sheet_name
            });
          }
        });

        setLabelResources(tmpLabelResources);
        setChildrenOptions(tmpChildrenOptions);
      }
    };

    let tmpFormData = { ...formData };
    tmpFormData.resources = prestation.resources.map(resource => {
      return {
        select: { value: resource.id, label: resource.invoice_name },
        id: resource.id,
        resource_id: resource.id,
        quantity: resource.pivot.quantity,
        quantity_production_sheet: resource.pivot.quantity_production_sheet,
        price: resource.pivot.price,
        discount: resource.pivot.discount || 0,
        vat: resource.pivot.vat,
        consumed: resource.pivot.consumed,
        isVaisselle: resource.isVaisselle,
        children:
          resource.isVaisselle && resource.children
            ? resource.children.map(child => ({
                id: child.id,
                name: child.invoice_name,
                quantity: child.pivot.quantity
              }))
            : []
      };
    });
    setFormData(tmpFormData);
    fetchResources();
  }, [trigger]);

  // eslint-disable-next-line no-unused-vars
  const addResource = index => {
    const newArray = { ...formData };
    if (!newArray.resources) {
      newArray.resources = [];
    }
    newArray.resources.splice(index + 1, 0, {
      select: {},
      id: '',
      quantity: 10,
      price: '',
      vat: ''
    });
    setFormData(newArray);
    setTimeout(function () {
      document
        .getElementById(`resource-${index + 1}`)
        .classList.add('content-clignote');
      document.getElementById(`resource-${index + 1}`).scrollIntoView();
    }, 150);
  };

  return (
    <>
      <Col>
        <Card className="mt-3">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Col className="d-flex" md="6">
                  <h5 className="pt-1">Ressources logistiques</h5>
                </Col>
              </Accordion.Header>
              <Accordion.Body ref={myRef} style={{ paddingBottom: '20px' }}>
                {errorForm && (
                  <div className="mt-3" id="errorForm">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                <Col xs={12}>
                  <Col className="text-end">
                    <CanvaNotes
                      setTrigger={setParentTrigger}
                      model={prestation}
                      noteableType={'prestation'}
                      context={'resources'}
                    />
                  </Col>
                </Col>
                <Col xs={12}>
                  {formData.resources.length === 0 ? (
                    <Row className="mb-3">
                      <Col>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          onClick={() => addResource(-1)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                              fill="rgba(94,110,130,1)"
                            />
                          </svg>{' '}
                          Ajouter une ressource
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    formData.resources.map((resource, index) => (
                      <React.Fragment key={index}>
                        <Row
                          className="align-items-center mb-4"
                          id={`resource-${index}`}
                        >
                          <Col lg={1}>
                            {index !== 0 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="cursor-pointer mt-4 me-3"
                                onClick={() => {
                                  let tmpFormData = { ...formData };
                                  let tmpResource =
                                    tmpFormData.resources[index];
                                  tmpFormData.resources[index] =
                                    tmpFormData.resources[index - 1];
                                  tmpFormData.resources[index - 1] =
                                    tmpResource;
                                  setFormData(tmpFormData);
                                }}
                              >
                                <path
                                  d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z"
                                  fill="rgba(94,110,130,1)"
                                />
                              </svg>
                            )}
                            {index !== formData.resources.length - 1 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="cursor-pointer mt-4 me-3"
                                onClick={() => {
                                  let tmpFormData = { ...formData };
                                  let tmpResource =
                                    tmpFormData.resources[index];
                                  tmpFormData.resources[index] =
                                    tmpFormData.resources[index + 1];
                                  tmpFormData.resources[index + 1] =
                                    tmpResource;
                                  setFormData(tmpFormData);
                                }}
                              >
                                <path
                                  d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"
                                  fill="rgba(94,110,130,1)"
                                />
                              </svg>
                            )}
                          </Col>
                          <Col lg={10}>
                            <Form.Group controlId="label">
                              <Form.Label className="mb-0">
                                Sélectionner une <b>ressource</b> :
                                <a
                                  onClick={() => {
                                    setShowCanvaAddResource(true);
                                  }}
                                  className="cursor-pointer ms-3 float-end"
                                  style={{ position: 'relative' }}
                                >
                                  <small>+ Créer une ressource</small>
                                </a>
                              </Form.Label>
                              <Select
                                className="mt-2"
                                closeMenuOnSelect={true}
                                options={labelResources}
                                value={resource.select}
                                placeholder="Sélectionner une ressource ..."
                                classNamePrefix="react-select"
                                onChange={object => {
                                  let selectedResource = labelResources.find(
                                    resource => resource.value === object.value
                                  );
                                  if (selectedResource) {
                                    let tmpFormData = { ...formData };
                                    tmpFormData.resources[index].select =
                                      object;
                                    tmpFormData.resources[index].id =
                                      selectedResource.value;
                                    tmpFormData.resources[index].resource_id =
                                      selectedResource.value;
                                    tmpFormData.resources[index].quantity = 0;
                                    tmpFormData.resources[
                                      index
                                    ].quantity_production_sheet = '';
                                    tmpFormData.resources[index].price =
                                      selectedResource.price;
                                    tmpFormData.resources[index].discount = 0;
                                    tmpFormData.resources[index].vat =
                                      selectedResource.vat;
                                    tmpFormData.resources[index].isVaisselle =
                                      selectedResource.isVaisselle;
                                    tmpFormData.resources[
                                      index
                                    ].childrenOptions =
                                      selectedResource.childrenOptions;
                                    if (selectedResource.isVaisselle) {
                                      tmpFormData.resources[index].children =
                                        [];
                                    }
                                    setFormData(tmpFormData);
                                  }
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={1}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                              className="float-end cursor-pointer mt-4 me-3"
                              onClick={() => {
                                let tmpFormData = { ...formData };
                                tmpFormData.resources.splice(index, 1);
                                setFormData(tmpFormData);
                              }}
                            >
                              <path
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                fill="rgba(229,47,18,1)"
                              />
                            </svg>
                          </Col>
                          {resource.select?.value && (
                            <Row className="mt-3">
                              <Col lg={1}></Col>
                              <Col lg={2}>
                                <Form.Group controlId="quantity">
                                  <Form.Label>Quantité</Form.Label>
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    name="quantity"
                                    value={resource.quantity}
                                    placeholder="Quantité de la ressource"
                                    onChange={e => {
                                      let tmpFormData = { ...formData };
                                      tmpFormData.resources[index].quantity =
                                        e.target.value;
                                      setFormData(tmpFormData);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={2}>
                                <Form.Group controlId="price">
                                  <Form.Label>Prix €</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="price"
                                    placeholder="Prix"
                                    onChange={e => {
                                      let tmpFormData = { ...formData };
                                      tmpFormData.resources[index].price =
                                        e.target.value;
                                      setFormData(tmpFormData);
                                    }}
                                    value={resource.price}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={2}>
                                <Form.Group controlId="price">
                                  <Form.Label>Remise %</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="discount"
                                    placeholder="Remise"
                                    onChange={e => {
                                      let tmpFormData = { ...formData };
                                      tmpFormData.resources[index].discount =
                                        e.target.value;
                                      setFormData(tmpFormData);
                                    }}
                                    value={resource.discount}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={1}>
                                <Form.Group controlId="vat">
                                  <Form.Label>TVA :</Form.Label>
                                  <Form.Select
                                    value={resource.vat}
                                    type="number"
                                    name="vat"
                                    placeholder="Taux TVA"
                                    onChange={e => {
                                      let tmpFormData = { ...formData };
                                      tmpFormData.resources[index].vat =
                                        e.target.value;
                                      setFormData(tmpFormData);
                                    }}
                                  >
                                    {resource.vat === '10.00' ? (
                                      <>
                                        <option value={'10.00'}>10 %</option>
                                        <option value={'5.50'}>5,50 %</option>
                                        <option value={'20.00'}>20 %</option>
                                      </>
                                    ) : resource.vat === '20.00' ? (
                                      <>
                                        <option value={'20.00'}>20 %</option>{' '}
                                        <option value={'5.50'}>5,50 %</option>
                                        <option value={'10.00'}>10 %</option>
                                      </>
                                    ) : (
                                      <>
                                        <option value={'5.50'}>5,50 %</option>
                                        <option value={'20.00'}>20 %</option>
                                        <option value={'10.00'}>10 %</option>
                                      </>
                                    )}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col lg={2}>
                                <Form.Group controlId="quantity">
                                  <Form.Label>Consommation</Form.Label>
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    name="consumed"
                                    value={
                                      resource.consumed ? resource.consumed : ''
                                    }
                                    placeholder="Quantité de la ressource"
                                    onChange={e => {
                                      let tmpFormData = { ...formData };
                                      tmpFormData.resources[index].consumed =
                                        e.target.value;
                                      setFormData(tmpFormData);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              {resource.isVaisselle && (
                                <Col lg={2}>
                                  <Form.Group controlId="quantity">
                                    <Form.Label>
                                      Quantité fiche de prod.
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      min={1}
                                      name="quantity_production_sheet"
                                      value={
                                        resource.quantity_production_sheet
                                          ? resource.quantity_production_sheet
                                          : ''
                                      }
                                      placeholder="Quantité fiche de production"
                                      onChange={e => {
                                        let tmpFormData = { ...formData };
                                        tmpFormData.resources[
                                          index
                                        ].quantity_production_sheet =
                                          e.target.value;
                                        setFormData(tmpFormData);
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              )}
                              {resource.isVaisselle && (
                                <>
                                  <Row className="mt-3">
                                    <Col lg={1}></Col>
                                    <Col lg={11}>
                                      <h6>Ajouter des détails</h6>
                                    </Col>
                                  </Row>
                                  {resource.children &&
                                    resource.children.map(
                                      (child, childIndex) => (
                                        <Row
                                          key={childIndex}
                                          className="align-items-center"
                                        >
                                          <Col lg={1}></Col>
                                          <Col lg={4}>
                                            <Form.Group
                                              controlId={`child-name-${index}-${childIndex}`}
                                            >
                                              <Form.Label>Nom</Form.Label>
                                              <Select
                                                classNamePrefix="react-select"
                                                isClearable
                                                value={
                                                  child.name
                                                    ? {
                                                        value: child.name,
                                                        label: child.name
                                                      }
                                                    : null
                                                }
                                                options={childrenOptions}
                                                placeholder="Sélectionner..."
                                                onChange={selectedOption => {
                                                  let tmpFormData = {
                                                    ...formData
                                                  };
                                                  tmpFormData.resources[
                                                    index
                                                  ].children[childIndex] = {
                                                    id: selectedOption
                                                      ? selectedOption.value
                                                      : '',
                                                    name: selectedOption
                                                      ? selectedOption.label
                                                      : '',
                                                    quantity: child.quantity
                                                  };
                                                  setFormData(tmpFormData);
                                                }}
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col lg={2}>
                                            <Form.Group
                                              controlId={`child-quantity-${index}-${childIndex}`}
                                            >
                                              <Form.Label>Quantité</Form.Label>
                                              <Form.Control
                                                type="number"
                                                min="1"
                                                value={child.quantity}
                                                onChange={e => {
                                                  let tmpFormData = {
                                                    ...formData
                                                  };
                                                  tmpFormData.resources[
                                                    index
                                                  ].children[
                                                    childIndex
                                                  ].quantity = e.target.value;
                                                  setFormData(tmpFormData);
                                                }}
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col lg={1}>
                                            <Button
                                              variant="danger"
                                              className="mt-4"
                                              onClick={() => {
                                                let tmpFormData = {
                                                  ...formData
                                                };
                                                tmpFormData.resources[
                                                  index
                                                ].children.splice(
                                                  childIndex,
                                                  1
                                                );
                                                setFormData(tmpFormData);
                                              }}
                                            >
                                              Supprimer
                                            </Button>
                                          </Col>
                                        </Row>
                                      )
                                    )}
                                  <Row className="mt-2">
                                    <Col lg={1}></Col>
                                    <Col lg={11}>
                                      <Button
                                        size="sm"
                                        variant="secondary"
                                        onClick={() => {
                                          let tmpFormData = { ...formData };
                                          if (
                                            !tmpFormData.resources[index]
                                              .children
                                          ) {
                                            tmpFormData.resources[
                                              index
                                            ].children = [];
                                          }
                                          tmpFormData.resources[
                                            index
                                          ].children.push({
                                            name: '',
                                            quantity: 1
                                          });
                                          setFormData(tmpFormData);
                                        }}
                                      >
                                        + Ajouter un détail
                                      </Button>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Row>
                          )}
                          <Col lg="12" className="pb-0 pt-3 mb-0 mt-3">
                            <div
                              style={{ borderBottom: '3px dashed #d8e2ef' }}
                            ></div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <Button
                              size="sm"
                              variant="outline-secondary"
                              onClick={() => addResource(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                                  fill="rgba(94,110,130,1)"
                                />
                              </svg>{' '}
                              Ajouter une ressource
                            </Button>
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))
                  )}
                </Col>
                {formData.resources.length >= 0 && (
                  <div
                    style={{
                      position: 'sticky',
                      bottom: 0,
                      backgroundColor: 'white',
                      padding: '10px',
                      borderTop: '1px solid #dee2e6',
                      textAlign: 'left',
                      marginTop: '20px',
                      marginLeft: '-20px',
                      marginRight: '-20px',
                      zIndex: 1
                    }}
                  >
                    <Button
                      disabled={isLoadingSubmit}
                      size="sm"
                      variant="primary"
                      onClick={handleSubmit}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>{' '}
                      Enregistrer les ressources{' '}
                    </Button>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Col>
      {showCanvaAddResource && (
        <ManageResource
          showResource={showCanvaAddResource}
          setShowResource={setShowCanvaAddResource}
          setLabelResources={setLabelResources}
          selectedObject={{}}
        />
      )}
    </>
  );
};

ManageLogisticService.propTypes = {
  prestation: PropTypes.object,
  labelResources: PropTypes.array,
  setLabelResources: PropTypes.func,
  setParentTrigger: PropTypes.func,
  fromButler: PropTypes.bool
};

export default ManageLogisticService;
