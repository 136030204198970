import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import DropdownFilter from 'components/common/DropdownFilter';
import AppContext from 'context/Context';
import esLocale from '@fullcalendar/core/locales/fr';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import * as Moment from 'moment';

const CalendarWaiters = ({ prestation, selectedObject }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState('');

  const [calendarApi, setCalendarApi] = useState({});
  const [changeViewFilter, setChangeViewFilter] = useState('Changer de vue');
  const navigate = useNavigate();
  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const handleEventClick = object => {
    navigate(`/maitre-d-hotel/prestation/${object.event.id}`);
  };

  const viewName = [
    'Affichage par mois',
    'Affichage semaine',
    'Affichage journée'
  ];

  const handleFilter = filter => {
    setChangeViewFilter(filter);
    switch (filter) {
      case 'Affichage par mois':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        localStorage.setItem('selectedTempViewAVS', 'month');
        break;
      case 'Affichage semaine':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        localStorage.setItem('selectedTempViewAVS', 'week');
        break;
      case 'Affichage journée':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        localStorage.setItem('selectedTempViewAVS', 'day');
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
        localStorage.setItem('selectedTempViewAVS', 'year');
    }
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
    let api = calendarRef.current.getApi();
    if (localStorage.getItem('selectedTempViewAVS')) {
      switch (localStorage.getItem('selectedTempViewAVS')) {
        case 'month':
          api.changeView('dayGridMonth');
          setTitle(api.getCurrentData().viewTitle);
          break;
        case 'week':
          api.changeView('timeGridWeek');
          setTitle(api.getCurrentData().viewTitle);
          break;
        case 'day':
          api.changeView('timeGridDay');
          setTitle(api.getCurrentData().viewTitle);
          break;
        default:
          api.changeView('listYear');
          setTitle(api.getCurrentData().viewTitle);
      }
    }
  }, []);

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, [selectedObject]);
  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="nextTooltip">Précédent</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    let selectedDate = '';
                    if (
                      calendarApi?.getCurrentData()?.viewApi?.type ===
                        'timeGridDay' ||
                      calendarApi?.getCurrentData()?.viewApi?.type ===
                        'timeGridWeek'
                    ) {
                      selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .local()
                        .format('YYYY-MM-DD');
                    } else {
                      selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .endOf('month')
                        .local()
                        .format('YYYY-MM-DD');
                    }
                    localStorage.setItem('selectedDateAVS', selectedDate);
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="previousTooltip">Suivant</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    let selectedDate = '';
                    if (
                      calendarApi?.getCurrentData()?.viewApi?.type ===
                        'timeGridDay' ||
                      calendarApi?.getCurrentData()?.viewApi?.type ===
                        'timeGridWeek'
                    ) {
                      selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .local()
                        .format('YYYY-MM-DD');
                    } else {
                      selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .endOf('month')
                        .local()
                        .format('YYYY-MM-DD');
                    }
                    localStorage.setItem('selectedDateAVS', selectedDate);
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col className="d-flex justify-content-start order-md-2 ms-3">
              <Button
                className="me-2"
                variant="falcon-default"
                size="sm"
                transform="shrink-3"
                onClick={() => {
                  calendarApi.today();
                  const selectedDate = Moment(
                    calendarApi.getCurrentData().currentDate
                  )
                    .local()
                    .format('YYYY-MM-DD');
                  localStorage.setItem('selectedDateAVS', selectedDate);
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Aujourd'hui
              </Button>
            </Col>

            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={changeViewFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            ref={calendarRef}
            locale={esLocale}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={10}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            editable
            selectable
            selectMirror
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={prestation}
            initialDate={
              localStorage.getItem('selectedDateAVS')
                ? new Date(localStorage.getItem('selectedDateAVS'))
                : new Date()
            }
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default CalendarWaiters;

CalendarWaiters.propTypes = {
  prestation: PropTypes.array.isRequired,
  selectedObject: PropTypes.object
};
