import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import TableBackCustomers from './table';
import { IndexCustomer } from '../../../services/CustomerService';
import show from '../waiters/calendar/Show';
import ManageCustomers from './manage';

const IndexCustomers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialApiCustomers, setInitialApiCustomers] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [showManageCustomers, setShowManageCustomers] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);
      if (initialApiCustomers.length > 0) {
        setIsLoading(false);
      }
      const responseCustomers = await IndexCustomer();
      if (responseCustomers.success === true) {
        setInitialApiCustomers(responseCustomers.data);
        setIsLoading(false);
      }
    };
    fetchCustomers();
  }, [trigger]);
  return (
    <>
      {show && (
        <ManageCustomers
          showManageCustomers={showManageCustomers}
          setShowManageCustomers={setShowManageCustomers}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          setParentTrigger={setTrigger}
        />
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                Gestion des clients
              </h3>
            </Col>
            <Col lg={6} className="text-end">
              <Button
                size="sm"
                onClick={() => {
                  setShowManageCustomers(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>{' '}
                Ajouter
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiCustomers.length > 0 && (
        <TableBackCustomers
          initialApiCustomers={initialApiCustomers}
          setParentTrigger={setTrigger}
          setSelectedObject={setSelectedObject}
          setShowManageCustomers={setShowManageCustomers}
        />
      )}
    </>
  );
};

export default IndexCustomers;
