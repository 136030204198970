import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import 'tippy.js/dist/tippy.css';

const App = () => {
  useEffect(() => {
    const preventScrollOnNumberInput = e => {
      const target = e.target;
      if (target.tagName === 'INPUT' && target.type === 'number') {
        e.preventDefault();
      }
    };

    window.addEventListener('wheel', preventScrollOnNumberInput, {
      passive: false
    });

    return () => {
      window.removeEventListener('wheel', preventScrollOnNumberInput);
    };
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
